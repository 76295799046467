import * as React from 'react'
import './header.css'
import logo from '../Images/logo.png'

// importing material UI components
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import { createTheme, ThemeProvider } from '@mui/material/styles'

// MATERIAL-UI
const { palette } = createTheme()
const { augmentColor } = palette
const createColor = mainColor => augmentColor({ color: { main: mainColor } })
const theme = createTheme({
  palette: {
    anger: createColor('#F40B27'),
    apple: createColor('#5DBA40'),
    steelBlue: createColor('#5C76B7'),
    violet: createColor('#BC00A3')
  }
})

export default function Header() {
  return (
    <ThemeProvider theme={theme}>
      <AppBar
        style={{
          'background-image': 'linear-gradient(to right, #51235C, #1F0A57)'
        }}
        position="sticky"
      >
        <Toolbar>
          <img src={logo} className="App-logo" alt="logo" />
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
          ></Typography>
          <Button
            style={{
              fontSize: 15,
              fontFamily: 'Barlow',
              textTransform: 'none',
              marginLeft: 20
            }}
            color="inherit"
          >
            Home
          </Button>
          <Button
            style={{
              fontSize: 15,
              fontFamily: 'Barlow',
              textTransform: 'none',
              marginLeft: 20
            }}
            color="inherit"
          >
            Planos
          </Button>
          <Button
            style={{
              fontSize: 15,
              fontFamily: 'Barlow',
              textTransform: 'none',
              marginLeft: 20
            }}
            color="inherit"
          >
            Contato
          </Button>
          <Button
            style={{
              fontSize: 15,
              fontFamily: 'Barlow',
              textTransform: 'none',
              marginLeft: 20
            }}
            color="inherit"
            href="https://wompplay.com"
          >
            Womp Play
          </Button>
          <Button
            style={{
              color: '#fff',
              borderRadius: 8,
              borderColor: '#FDEB1A',
              padding: '4px 6px',
              marginLeft: 20,
              fontSize: 15,
              fontFamily: 'Barlow',
              textTransform: 'none'
            }}
            variant="outlined"
            href="https://ixc.nbntelecom.com.br/central_assinante_web/login"
          >
            {' '}
            Central do assinante
          </Button>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  )
}
