import React, { useState } from 'react'

import Carousel from 'react-elastic-carousel'

import Item from './Item'
import './styles.css'

const breakPoints = [
  { width: 1, itemsToShow: 2, itemsToScroll: 1 },
  { width: 550, itemsToShow: 2, itemsToScroll: 2 },
  { width: 768, itemsToShow: 3, itemsToScroll: 3 },
  { width: 1200, itemsToShow: 4, itemsToScroll: 1 }
]

export default function Planos({
  number,
  numberInfo,
  infoUp,
  infoBottom,
  price
}) {
  const [location, setLocation] = useState('Manaus') // Change the location here

  const handleLocationChange = newLocation => {
    setLocation(newLocation)
  }

  const manausPlanes = [
    {
      id: 1,
      number: 100,
      info: 'MEGA',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      price: 'R$ 99,99'
    },
    {
      id: 2,
      number: 200,
      info: 'MEGA',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      price: 'R$ 129,99'
    },
    {
      id: 3,
      number: 300,
      info: 'MEGA',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      price: 'R$ 139,99'
    },
    {
      id: 4,
      number: 500,
      info: 'MEGA',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',

      price: 'R$ 159,99'
    },
    {
      id: 5,
      number: 300,
      info: 'MEGA + TV NO APP',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'STAR',
      price: 'R$ 119,99'
    },
    {
      id: 6,
      number: 300,
      info: 'MEGA + TV NO APP',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'FULL',
      price: 'R$ 249,99'
    },
    {
      id: 7,
      number: 400,
      info: 'MEGA + TV NO APP',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'STAR',
      price: 'R$ 129,99'
    },
    {
      id: 8,
      number: 400,
      info: 'MEGA + TV NO APP',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'FULL',
      price: 'R$ 259,99'
    },
    {
      id: 9,
      number: 500,
      info: 'MEGA + TV NO APP',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'STAR',
      price: 'R$ 139,99'
    },
    {
      id: 10,
      number: 500,
      info: 'MEGA + TV NO APP',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'FULL',
      price: 'R$ 269,99'
    },
    {
      id: 11,
      number: 300,
      info: 'MEGA + TV NO APP + ROKU',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'STAR',
      price: 'R$ 139,99'
    },
    {
      id: 12,
      number: 300,
      info: 'MEGA + TV NO APP + ROKU',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'FULL',
      price: 'R$ 269,99'
    },
    {
      id: 13,
      number: 400,
      info: 'MEGA + TV NO APP + ROKU',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'STAR',
      price: 'R$ 149,99'
    },
    {
      id: 14,
      number: 400,
      info: 'MEGA + TV NO APP + ROKU',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'FULL',
      price: 'R$ 279,99'
    },
    {
      id: 15,
      number: 500,
      info: 'MEGA + TV NO APP + ROKU',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'STAR',
      price: 'R$ 159,99'
    },
    {
      id: 16,
      number: 500,
      info: 'MEGA + TV NO APP + ROKU',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'FULL',
      price: 'R$ 289,99'
    }
  ]

  const irandubaPlanes = [
    {
      id: 1,
      number: 100,
      info: 'MEGA',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      price: 'R$ 99,99'
    },
    {
      id: 2,
      number: 200,
      info: 'MEGA',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      price: 'R$ 129,99'
    },
    {
      id: 3,
      number: 300,
      info: 'MEGA',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      price: 'R$ 139,99'
    },
    {
      id: 4,
      number: 500,
      info: 'MEGA',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',

      price: 'R$ 159,99'
    },
    {
      id: 5,
      number: 300,
      info: 'MEGA + TV NO APP',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'STAR',
      price: 'R$ 119,99'
    },
    {
      id: 6,
      number: 300,
      info: 'MEGA + TV NO APP',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'FULL',
      price: 'R$ 249,99'
    },
    {
      id: 7,
      number: 400,
      info: 'MEGA + TV NO APP',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'STAR',
      price: 'R$ 129,99'
    },
    {
      id: 8,
      number: 400,
      info: 'MEGA + TV NO APP',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'FULL',
      price: 'R$ 259,99'
    },
    {
      id: 9,
      number: 500,
      info: 'MEGA + TV NO APP',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'STAR',
      price: 'R$ 139,99'
    },
    {
      id: 10,
      number: 500,
      info: 'MEGA + TV NO APP',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'FULL',
      price: 'R$ 269,99'
    },
    {
      id: 11,
      number: 300,
      info: 'MEGA + TV NO APP + ROKU',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'STAR',
      price: 'R$ 139,99'
    },
    {
      id: 12,
      number: 300,
      info: 'MEGA + TV NO APP + ROKU',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'FULL',
      price: 'R$ 269,99'
    },
    {
      id: 13,
      number: 400,
      info: 'MEGA + TV NO APP + ROKU',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'STAR',
      price: 'R$ 149,99'
    },
    {
      id: 14,
      number: 400,
      info: 'MEGA + TV NO APP + ROKU',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'FULL',
      price: 'R$ 279,99'
    },
    {
      id: 15,
      number: 500,
      info: 'MEGA + TV NO APP + ROKU',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'STAR',
      price: 'R$ 159,99'
    },
    {
      id: 16,
      number: 500,
      info: 'MEGA + TV NO APP + ROKU',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'FULL',
      price: 'R$ 289,99'
    }
  ]
  const itacoatiaraPlanes = [
    {
      id: 1,
      number: 100,
      info: 'MEGA',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      price: 'R$ 99,99'
    },
    {
      id: 2,
      number: 200,
      info: 'MEGA',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      price: 'R$ 129,99'
    },
    {
      id: 3,
      number: 300,
      info: 'MEGA',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      price: 'R$ 139,99'
    },
    {
      id: 4,
      number: 500,
      info: 'MEGA',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',

      price: 'R$ 159,99'
    },
    {
      id: 5,
      number: 300,
      info: 'MEGA + TV NO APP',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'STAR',
      price: 'R$ 119,99'
    },
    {
      id: 6,
      number: 300,
      info: 'MEGA + TV NO APP',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'FULL',
      price: 'R$ 249,99'
    },
    {
      id: 7,
      number: 400,
      info: 'MEGA + TV NO APP',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'STAR',
      price: 'R$ 129,99'
    },
    {
      id: 8,
      number: 400,
      info: 'MEGA + TV NO APP',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'FULL',
      price: 'R$ 259,99'
    },
    {
      id: 9,
      number: 500,
      info: 'MEGA + TV NO APP',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'STAR',
      price: 'R$ 139,99'
    },
    {
      id: 10,
      number: 500,
      info: 'MEGA + TV NO APP',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'FULL',
      price: 'R$ 269,99'
    },
    {
      id: 11,
      number: 300,
      info: 'MEGA + TV NO APP + ROKU',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'STAR',
      price: 'R$ 139,99'
    },
    {
      id: 12,
      number: 300,
      info: 'MEGA + TV NO APP + ROKU',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'FULL',
      price: 'R$ 269,99'
    },
    {
      id: 13,
      number: 400,
      info: 'MEGA + TV NO APP + ROKU',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'STAR',
      price: 'R$ 149,99'
    },
    {
      id: 14,
      number: 400,
      info: 'MEGA + TV NO APP + ROKU',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'FULL',
      price: 'R$ 279,99'
    },
    {
      id: 15,
      number: 500,
      info: 'MEGA + TV NO APP + ROKU',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'STAR',
      price: 'R$ 159,99'
    },
    {
      id: 16,
      number: 500,
      info: 'MEGA + TV NO APP + ROKU',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'FULL',
      price: 'R$ 289,99'
    }
  ]

  const parintinsPlanes = [
    {
      id: 1,
      number: 10,
      info: 'MEGA',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      price: 'R$ 99,99'
    },
    {
      id: 2,
      number: 30,
      info: 'MEGA',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      price: 'R$ 199,99'
    },
    {
      id: 3,
      number: 40,
      info: 'MEGA',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      price: 'R$ 249,99'
    },
    {
      id: 4,
      number: 25,
      info: 'MEGA LTE',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      price: 'R$ 299,99'
    },
    {
      id: 5,
      number: 10,
      info: 'MEGA + TV NO APP',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'STAR',
      price: 'R$ 109,99'
    },
    {
      id: 6,
      number: 10,
      info: 'MEGA + TV NO APP',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'FULL',
      price: 'R$ 239,99'
    },
    {
      id: 7,
      number: 30,
      info: 'MEGA + TV NO APP',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'STAR',
      price: 'R$ 209,99'
    },
    {
      id: 8,
      number: 30,
      info: 'MEGA + TV NO APP',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'FULL',
      price: 'R$ 339,99'
    },
    {
      id: 9,
      number: 40,
      info: 'MEGA + TV NO APP',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'STAR',
      price: 'R$ 259,99'
    },
    {
      id: 10,
      number: 40,
      info: 'MEGA + TV NO APP',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'FULL',
      price: 'R$ 389,99'
    },
    {
      id: 11,
      number: 25,
      info: 'MEGA LTE + TV NO APP',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'STAR',
      price: 'R$ 309,99'
    },
    {
      id: 12,
      number: 25,
      info: 'MEGA LTE + TV NO APP',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'FULL',
      price: 'R$ 439,99'
    },
    {
      id: 13,
      number: 10,
      info: 'MEGA + TV NO APP + ROKU',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'STAR',
      price: 'R$ 129,99'
    },
    {
      id: 14,
      number: 10,
      info: 'MEGA + TV NO APP + ROKU',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'FULL',
      price: 'R$ 259,99'
    },
    {
      id: 15,
      number: 30,
      info: 'MEGA + TV NO APP + ROKU',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'STAR',
      price: 'R$ 229,99'
    },
    {
      id: 16,
      number: 30,
      info: 'MEGA + TV NO APP + ROKU',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'FULL',
      price: 'R$ 359,99'
    },
    {
      id: 17,
      number: 40,
      info: 'MEGA + TV NO APP + ROKU',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'STAR',
      price: 'R$ 279,99'
    },
    {
      id: 18,
      number: 40,
      info: 'MEGA + TV NO APP + ROKU',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'FULL',
      price: 'R$ 409,99'
    },
    {
      id: 19,
      number: 25,
      info: 'MEGA LTE + TV NO APP + ROKU',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'STAR',
      price: 'R$ 329,99'
    },
    {
      id: 20,
      number: 30,
      info: 'MEGA LTE + TV NO APP + ROKU',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'FULL',
      price: 'R$ 459,99'
    }
  ]

  const mauesPlanes = [
    {
      id: 1,
      number: 20,
      info: 'MEGA',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      price: 'R$ 149,99'
    },
    {
      id: 2,
      number: 30,
      info: 'MEGA',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      price: 'R$ 199,99'
    },
    {
      id: 3,
      number: 40,
      info: 'MEGA',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      price: 'R$ 249,99'
    },
    {
      id: 4,
      number: 20,
      info: 'MEGA + TV NO APP',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'STAR',
      price: 'R$ 159,99'
    },
    {
      id: 5,
      number: 20,
      info: 'MEGA + TV NO APP',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'FULL',
      price: 'R$ 289,99'
    },
    {
      id: 6,
      number: 30,
      info: 'MEGA + TV NO APP',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'STAR',
      price: 'R$ 209,99'
    },
    {
      id: 7,
      number: 30,
      info: 'MEGA + TV NO APP',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'FULL',
      price: 'R$ 339,99'
    },
    {
      id: 8,
      number: 40,
      info: 'MEGA + TV NO APP',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'STAR',
      price: 'R$ 259,99'
    },
    {
      id: 9,
      number: 40,
      info: 'MEGA + TV NO APP',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'FULL',
      price: 'R$ 389,99'
    },
    {
      id: 10,
      number: 20,
      info: 'MEGA + TV NO APP + ROKU',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'STAR',
      price: 'R$ 179,99'
    },
    {
      id: 11,
      number: 20,
      info: 'MEGA + TV NO APP + ROKU',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'FULL',
      price: 'R$ 309,99'
    },
    {
      id: 12,
      number: 30,
      info: 'MEGA + TV NO APP + ROKU',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'STAR',
      price: 'R$ 229,99'
    },
    {
      id: 13,
      number: 30,
      info: 'MEGA + TV NO APP + ROKU',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'FULL',
      price: 'R$ 359,99'
    },
    {
      id: 14,
      number: 40,
      info: 'MEGA + TV NO APP + ROKU',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'STAR',
      price: 'R$ 279,99'
    },
    {
      id: 15,
      number: 40,
      info: 'MEGA + TV NO APP + ROKU',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'FULL',
      price: 'R$ 409,99'
    }
  ]

  const lindoiaPlanes = [
    {
      id: 1,
      number: 10,
      info: 'MEGA',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      price: 'R$ 99,99'
    },
    {
      id: 2,
      number: 40,
      info: 'MEGA',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      price: 'R$ 149,99'
    },
    {
      id: 3,
      number: 100,
      info: 'MEGA',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      price: 'R$ 199,99'
    },
    {
      id: 4,
      number: 10,
      info: 'MEGA + TV NO APP',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'STAR',
      price: 'R$ 109,99'
    },
    {
      id: 5,
      number: 10,
      info: 'MEGA + TV NO APP',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'FULL',
      price: 'R$ 239,99'
    },
    {
      id: 6,
      number: 40,
      info: 'MEGA + TV NO APP',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'STAR',
      price: 'R$ 159,99'
    },
    {
      id: 7,
      number: 40,
      info: 'MEGA + TV NO APP',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'FULL',
      price: 'R$ 289,99'
    },
    {
      id: 8,
      number: 100,
      info: 'MEGA + TV NO APP',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'STAR',
      price: 'R$ 209,99'
    },
    {
      id: 9,
      number: 100,
      info: 'MEGA + TV NO APP',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'FULL',
      price: 'R$ 339,99'
    },
    {
      id: 10,
      number: 10,
      info: 'MEGA + TV NO APP + ROKU',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'STAR',
      price: 'R$ 129,99'
    },
    {
      id: 11,
      number: 10,
      info: 'MEGA + TV NO APP + ROKU',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'FULL',
      price: 'R$ 259,99'
    },
    {
      id: 12,
      number: 40,
      info: 'MEGA + TV NO APP + ROKU',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'STAR',
      price: 'R$ 179,99'
    },
    {
      id: 13,
      number: 40,
      info: 'MEGA + TV NO APP + ROKU',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'FULL',
      price: 'R$ 309,99'
    },
    {
      id: 14,
      number: 100,
      info: 'MEGA + TV NO APP + ROKU',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'STAR',
      price: 'R$ 229,99'
    },
    {
      id: 15,
      number: 100,
      info: 'MEGA + TV NO APP + ROKU',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'FULL',
      price: 'R$ 359,99'
    }
  ]

  const manapolisPlanes = [
    {
      id: 1,
      number: 20,
      info: 'MEGA',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      price: 'R$ 199,99'
    },
    {
      id: 2,
      number: 30,
      info: 'MEGA',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      price: 'R$ 229,99'
    },
    {
      id: 3,
      number: 40,
      info: 'MEGA',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      price: 'R$ 249,99'
    },
    {
      id: 4,
      number: 20,
      info: 'MEGA + TV NO APP',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'STAR',
      price: 'R$ 209,99'
    },
    {
      id: 5,
      number: 20,
      info: 'MEGA + TV NO APP',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'FULL',
      price: 'R$ 339,99'
    },
    {
      id: 6,
      number: 30,
      info: 'MEGA + TV NO APP',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'STAR',
      price: 'R$239,99'
    },
    {
      id: 7,
      number: 30,
      info: 'MEGA + TV NO APP',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'FULL',
      price: 'R$ 369,99'
    },
    {
      id: 8,
      number: 40,
      info: 'MEGA + TV NO APP',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'STAR',
      price: 'R$ 259,99'
    },
    {
      id: 9,
      number: 40,
      info: 'MEGA + TV NO APP',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'FULL',
      price: 'R$ 389,99'
    },
    {
      id: 10,
      number: 20,
      info: 'MEGA + TV NO APP + ROKU',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'STAR',
      price: 'R$ 229,99'
    },
    {
      id: 11,
      number: 20,
      info: 'MEGA + TV NO APP + ROKU',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'FULL',
      price: 'R$ 359,99'
    },
    {
      id: 12,
      number: 30,
      info: 'MEGA + TV NO APP + ROKU',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'STAR',
      price: 'R$ 259,99'
    },
    {
      id: 13,
      number: 30,
      info: 'MEGA + TV NO APP + ROKU',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'FULL',
      price: 'R$ 389,99'
    },
    {
      id: 14,
      number: 40,
      info: 'MEGA + TV NO APP + ROKU',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'STAR',
      price: 'R$ 279,99'
    },
    {
      id: 15,
      number: 40,
      info: 'MEGA + TV NO APP + ROKU',
      info1: 'WI-FI WOMP',
      info2: 'GRATUITO',
      info3: 'SUPORTE 24H',
      info4: 'GRATUITO',
      info5: 'INTERNET',
      info6: 'SEM FRANQUIA',
      info7: 'PLANO TV',
      info8: 'FULL',
      price: 'R$ 409,99'
    }
  ]

  return (
    <div
      style={{
        background: 'linear-gradient(to right, #51235C, #1F0A57)'
      }}
    >
      <div>
        <text
          style={{
            color: '#1F0A57',
            fontSize: '25px',
            fontFamily: 'Barlow',
            fontWeight: 700,
            borderRadius: '20px',
            background: '#FDEB1A',
            paddingTop: '10px',
            paddingBottom: '20px',
            paddingLeft: '15px',
            paddingRight: '15px'
          }}
        >
          Informações Válidas apenas para {location}
        </text>
      </div>
      <div
        style={{
          marginTop: '40px',
          display: 'flex',
          alignItems: 'center', // Centralizar verticalmente
          justifyContent: 'center' // Centralizar horizontalmente (opcional)
        }}
      >
        <text
          style={{
            color: '#1F0A57',
            fontSize: '14px',
            fontFamily: 'Barlow',
            fontWeight: 700,
            borderRadius: '20px',
            background: '#FDEB1A',
            paddingTop: '10px',
            paddingBottom: '10px',
            paddingLeft: '15px',
            paddingRight: '15px'
          }}
        >
          {' '}
          Você não é de {location}? Escolha a sua localidade:
          <select
            onChange={e => handleLocationChange(e.target.value)}
            style={{
              color: '#FFF',
              fontSize: '12px',
              fontFamily: 'Barlow',
              fontWeight: 700,
              border: '0px',
              borderRadius: '8px',
              background: '#51235C',
              paddingTop: '8px',
              paddingBottom: '8px'
            }}
          >
            <option value="Manaus">Manaus</option>
            <option value="Iranduba">Iranduba</option>
            <option value="Itacoatiara">Itacoatiara</option>
            <option value="Parintins">Parintins</option>
            <option value="Maues">Maués</option>
            <option value="Lindoia">Lindoia</option>
            <option value="Manapolis">Manapolis</option>
          </select>
        </text>
      </div>
      <Carousel
        breakPoints={breakPoints}
        /* -- TRANSITION ANIMADA
        easing="cubic-bezier(1,.15,.55,1.54)"
        tiltEasing="cubic-bezier(0.110, 1, 1.000, 0.210)"
        transitionMs={700}
        */
        enableAutoPlay={false}
        autoPlaySpeed={10000}
        pagination={false}
        itemsToShow={4}
        itemsToScroll={4}
      >
        {location === 'Manaus'
          ? manausPlanes.map(item => (
              <div className="Styled-item">
                <p className="Number">{item.number}</p>
                <p className="Number-info">{item.info}</p>
                <p className="Info-up">{item.info1}</p>
                <p className="Info-bottom">{item.info2}</p>
                <p className="Info-up"> {item.info3}</p>
                <p className="Info-bottom">{item.info4}</p>
                <p className="Info-up">{item.info5}</p>
                <p className="Info-bottom">{item.info6}</p>
                <p className="Info-up">{item.info7}</p>
                <p className="Info-bottom">{item.info8}</p>
                <p className="Preço">{item.price}</p>
              </div>
            ))
          : location === 'Iranduba'
          ? irandubaPlanes.map(item => (
              <div className="Styled-item">
                <p className="Number">{item.number}</p>
                <p className="Number-info">{item.info}</p>
                <p className="Info-up">{item.info1}</p>
                <p className="Info-bottom">{item.info2}</p>
                <p className="Info-up"> {item.info3}</p>
                <p className="Info-bottom">{item.info4}</p>
                <p className="Info-up">{item.info5}</p>
                <p className="Info-bottom">{item.info6}</p>
                <p className="Info-up">{item.info7}</p>
                <p className="Info-bottom">{item.info8}</p>
                <p className="Preço">{item.price}</p>
              </div>
            ))
          : location === 'Itacoatiara'
          ? itacoatiaraPlanes.map(item => (
              <div className="Styled-item">
                <p className="Number">{item.number}</p>
                <p className="Number-info">{item.info}</p>
                <p className="Info-up">{item.info1}</p>
                <p className="Info-bottom">{item.info2}</p>
                <p className="Info-up"> {item.info3}</p>
                <p className="Info-bottom">{item.info4}</p>
                <p className="Info-up">{item.info5}</p>
                <p className="Info-bottom">{item.info6}</p>
                <p className="Info-up">{item.info7}</p>
                <p className="Info-bottom">{item.info8}</p>
                <p className="Preço">{item.price}</p>
              </div>
            ))
          : location === 'Parintins'
          ? parintinsPlanes.map(item => (
              <div className="Styled-item">
                <p className="Number">{item.number}</p>
                <p className="Number-info">{item.info}</p>
                <p className="Info-up">{item.info1}</p>
                <p className="Info-bottom">{item.info2}</p>
                <p className="Info-up"> {item.info3}</p>
                <p className="Info-bottom">{item.info4}</p>
                <p className="Info-up">{item.info5}</p>
                <p className="Info-bottom">{item.info6}</p>
                <p className="Info-up">{item.info7}</p>
                <p className="Info-bottom">{item.info8}</p>
                <p className="Preço">{item.price}</p>
              </div>
            ))
          : location === 'Maues'
          ? mauesPlanes.map(item => (
              <div className="Styled-item">
                <p className="Number">{item.number}</p>
                <p className="Number-info">{item.info}</p>
                <p className="Info-up">{item.info1}</p>
                <p className="Info-bottom">{item.info2}</p>
                <p className="Info-up"> {item.info3}</p>
                <p className="Info-bottom">{item.info4}</p>
                <p className="Info-up">{item.info5}</p>
                <p className="Info-bottom">{item.info6}</p>
                <p className="Info-up">{item.info7}</p>
                <p className="Info-bottom">{item.info8}</p>
                <p className="Preço">{item.price}</p>
              </div>
            ))
          : location === 'Lindoia'
          ? lindoiaPlanes.map(item => (
              <div className="Styled-item">
                <p className="Number">{item.number}</p>
                <p className="Number-info">{item.info}</p>
                <p className="Info-up">{item.info1}</p>
                <p className="Info-bottom">{item.info2}</p>
                <p className="Info-up"> {item.info3}</p>
                <p className="Info-bottom">{item.info4}</p>
                <p className="Info-up">{item.info5}</p>
                <p className="Info-bottom">{item.info6}</p>
                <p className="Info-up">{item.info7}</p>
                <p className="Info-bottom">{item.info8}</p>
                <p className="Preço">{item.price}</p>
              </div>
            ))
          : location === 'Manapolis'
          ? manapolisPlanes.map(item => (
              <div className="Styled-item">
                <p className="Number">{item.number}</p>
                <p className="Number-info">{item.info}</p>
                <p className="Info-up">{item.info1}</p>
                <p className="Info-bottom">{item.info2}</p>
                <p className="Info-up"> {item.info3}</p>
                <p className="Info-bottom">{item.info4}</p>
                <p className="Info-up">{item.info5}</p>
                <p className="Info-bottom">{item.info6}</p>
                <p className="Info-up">{item.info7}</p>
                <p className="Info-bottom">{item.info8}</p>
                <p className="Preço">{item.price}</p>
              </div>
            ))
          : null}
      </Carousel>

      <div
        style={{
          marginTop: '40px'
        }}
      >
        >
        <div>
          <text
            style={{
              color: '#1F0A57',
              fontSize: '25px',
              fontFamily: 'Barlow',
              fontWeight: 700,
              borderRadius: '20px',
              background: '#FDEB1A',
              paddingTop: '10px',
              paddingBottom: '20px',
              paddingLeft: '15px',
              paddingRight: '15px'
            }}
          >
            Womp Play válido para todo o Brasil
          </text>
        </div>
        <div
          style={{
            marginTop: '40px',
            display: 'flex',
            alignItems: 'center', // Centralizar verticalmente
            justifyContent: 'center' // Centralizar horizontalmente (opcional)
          }}
        >
          <text
            style={{
              color: '#1F0A57',
              fontSize: '14px',
              fontFamily: 'Barlow',
              fontWeight: 700,
              borderRadius: '20px',
              background: '#FDEB1A',
              paddingTop: '10px',
              paddingBottom: '10px',
              paddingLeft: '15px',
              paddingRight: '15px'
            }}
          >
            {' '}
            Quer saber mais sobre a womp play?{' '}
            <a
              href="https://www.wompplay.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Clique Aqui
            </a>
          </text>
        </div>
      </div>
      <Carousel
        breakPoints={breakPoints}
        /* -- TRANSITION ANIMADA
        easing="cubic-bezier(1,.15,.55,1.54)"
        tiltEasing="cubic-bezier(0.110, 1, 1.000, 0.210)"
        transitionMs={700}
        */
        enableAutoPlay={false}
        autoPlaySpeed={10000}
        pagination={false}
        itemsToShow={4}
        itemsToScroll={4}
      >
        <Item className="Styled-item">
          <p className="Number">120</p>
          <p className="Number-info">CANAIS ABERTOS</p>
          <p className="Info-up">TELAS</p>
          <p className="Info-bottom">2</p>
          <p className="Info-up"> SUPORTE 24H</p>
          <p className="Info-bottom">GRATUITO</p>
          <p className="Info-up">OPERADORA</p>
          <p className="Info-bottom">TODO BRASIL</p>
          <p className="Preço">R$ 19,99</p>
        </Item>
        <Item className="Styled-item">
          <p className="Number">185</p>
          <p className="Number-info">CANAIS ABERTOS + FECHADOS</p>
          <p className="Info-up">TELAS</p>
          <p className="Info-bottom">3</p>
          <p className="Info-up"> SUPORTE 24H</p>
          <p className="Info-bottom">GRATUITO</p>
          <p className="Info-up">OPERADORA</p>
          <p className="Info-bottom">TODO BRASIL</p>
          <p className="Preço">R$ 139,99</p>
        </Item>
      </Carousel>
    </div>
  )
}
