function PrivacidadeSeventh() {
  return (
    <div style={{ paddingLeft: '2rem', backgroundColor: '#13072B' }}>
      <h2>
        <span>Política de Privacidade</span>
      </h2>

      <h3>
        <span>
          1. Informações gerais
          {/* ... (restante das informações) */}
        </span>
      </h3>
      <p>
        A presente Política de Privacidade contém informações a respeito do modo
        como tratamos, total ou parcialmente, de forma automatizada ou não, os
        dados pessoais dos usuários que acessam nosso site. Seu objetivo é
        esclarecer os interessados acerca dos tipos de dados que são coletados,
        dos motivos da coleta e da forma como o usuário poderá atualizar,
        gerenciar ou excluir estas informações.
      </p>
      <p>
        Esta Política de Privacidade foi elaborada em conformidade com a Lei
        Federal n. 12.965 de 23 de abril de 2014 (Marco Civil da Internet), com
        a Lei Federal n. 13.709, de 14 de agosto de 2018 (Lei de Proteção de
        Dados Pessoais).
      </p>
      <p>
        Esta Política de Privacidade poderá ser atualizada em decorrência de
        eventual atualização normativa, razão pela qual se convida o usuário a
        consultar periodicamente esta seção.
      </p>

      <h3>
        <span>
          2. Direitos do usuário
          {/* ... (restante das informações) */}
        </span>
      </h3>

      <p>
        O site se compromete a cumprir as normas previstas na LGPD, em respeito
        aos seguintes princípios:
      </p>
      <ul>
        <li>
          - Os dados pessoais do usuário serão processados de forma lícita, leal
          e transparente (licitude, lealdade e transparência);
        </li>
        <li>
          - Os dados pessoais do usuário serão coletados apenas para finalidades
          determinadas, explícitas e legítimas, não podendo ser tratados
          posteriormente de uma forma incompatível com essas finalidades
          (limitação das finalidades);
        </li>
        <li>
          - Os dados pessoais do usuário serão coletados de forma adequada,
          pertinente e limitada às necessidades do objetivo para os quais eles
          são processados (minimização dos dados);
        </li>
        <li>
          - Os dados pessoais do usuário serão exatos e atualizados sempre que
          necessário, de maneira que os dados inexatos sejam apagados ou
          retificados quando possível (exatidão);
        </li>
        <li>
          - Os dados pessoais do usuário serão conservados de uma forma que
          permita a identificação dos titulares dos dados apenas durante o
          período necessário para as finalidades para as quais são tratados
          (limitação da conservação);
        </li>
        <li>
          - Os dados pessoais do usuário serão tratados de forma segura,
          protegidos do tratamento não autorizado ou ilícito e contra a sua
          perda, destruição ou danificação acidental, adotando as medidas
          técnicas ou organizativas adequadas (integridade e confidencialidade).
        </li>
        <li>
          - O usuário do site possui os seguintes direitos, conferidos pela Lei
          de Proteção de Dados Pessoais.
        </li>
        <li>
          - Direito de confirmação e acesso: é o direito do usuário de obter do
          site a confirmação de que os dados pessoais que lhe digam respeito são
          ou não objeto de tratamento e, se for esse o caso, o direito de
          acessar os seus dados pessoais;
        </li>
        <li>
          - Direito de retificação: é o direito do usuário de obter do site, sem
          demora injustificada, a retificação dos dados pessoais inexatos que
          lhe digam respeito
        </li>
        <li>
          - Direito à eliminação dos dados (direito ao esquecimento): é o
          direito do usuário de ter seus dados apagados do site;
        </li>
        <li>
          - Direito à limitação do tratamento dos dados: é o direito do usuário
          de limitar o tratamento de seus dados pessoais, podendo obtê-la quando
          contesta a exatidão dos dados, quando o tratamento for ilícito, quando
          o site não precisar mais dos dados para as finalidades propostas e
          quando tiver se oposto ao tratamento dos dados e em caso de tratamento
          de dados desnecessários;
        </li>
        <li>
          - Direito de oposição: é o direito do usuário de, a qualquer momento,
          se opor por motivos relacionados com a sua situação particular, ao
          tratamento dos dados pessoais que lhe digam respeito, podendo se opor
          ainda ao uso de seus dados pessoais para definição de perfil
          de marketing (profiling);
        </li>
        <li>
          - Direito de portabilidade dos dados: é o direito do usuário de
          receber os dados pessoais que lhe digam respeito e que tenha fornecido
          ao site, num formato estruturado, de uso corrente e de leitura
          automática, e o direito de transmitir esses dados a outro site;
        </li>
        <li>
          - Direito de não ser submetido a decisões automatizadas: é o direito
          do usuário de não ficar sujeito a nenhuma decisão tomada
          exclusivamente com base no tratamento automatizado, incluindo a
          definição de perfis (profiling), que produza efeitos na sua esfera
          jurídica ou que o afete significativamente de forma similar.
        </li>
      </ul>
      <p>
        O usuário poderá exercer os seus direitos por meio de comunicação
        escrita enviada ao site com o assunto "LGDP-www.nbn.com.br",
        especificando:
      </p>
      <ul>
        <li>
          - Nome completo ou razão social, número do CPF (Cadastro de Pessoas
          Físicas, da Receita Federal do Brasil) ou CNPJ (Cadastro Nacional de
          Pessoa Jurídica, da Receita Federal do Brasil) e endereço de e-mail do
          usuário e, se for o caso, do seu representante;
        </li>
        <li>- Direito que deseja exercer junto ao site;</li>
        <li>- Data do pedido e assinatura do usuário;</li>
        <li>
          - Todo documento que possa demonstrar ou justificar o exercício de seu
          direito.
        </li>
      </ul>
      <p>
        O pedido deverá ser enviado ao e-mail: atendimento@nbn.com.br ou por
        correio, ao seguinte endereço:
      </p>
      <p>NORTE BRASIL NETWORK TELECOMUNICAÇÔES LTDA-ME</p>
      <p> Av. Margarita, n⁰ 5712, Bairro: Cidade de Deus Manaus/Am</p>
      <p>
        O usuário será informado em caso de retificação ou eliminação dos seus
        dados.
      </p>
      <h3>
        <span>
          3. Dever de não fornecer dados de terceiros
          {/* ... (restante das informações) */}
        </span>
      </h3>
      <p>
        Durante a utilização do site, a fim de resguardar e de proteger os
        direitos de terceiros, o usuário do site deverá fornecer somente seus
        dados pessoais, e não os de terceiros.
      </p>
      <h3>
        <span>
          4. Informações coletadas
          {/* ... (restante das informações) */}
        </span>
      </h3>
      <p>
        A coleta de dados dos usuários se dará em conformidade com o disposto
        nesta Política de Privacidade e dependerá do consentimento do usuário,
        sendo este dispensável somente nas hipóteses previstas no art. 11,
        inciso II, da Lei Geral de Proteção de Dados.
      </p>

      <h3>
        <span>
          4.1. Tipos de dados coletados
          {/* ... (restante das informações) */}
        </span>
      </h3>

      <h3>
        <span>
          4.1.1. Dados de identificação do usuário para realização de cadastro
          {/* ... (restante das informações) */}
        </span>
      </h3>
      <p>
        A utilização, pelo usuário, de determinadas funcionalidades do site
        dependerá de cadastro, sendo que, nestes casos, os seguintes dados do
        usuário serão coletados e armazenados:
      </p>
      <ul>
        <li>- nome</li>
        <li>- endereço completo</li>
        <li>- endereço de e-mail</li>
        <li>- número de CPF</li>
        <li>- número de CNPJ</li>
        <li>- telefone</li>
        <li>- Data Nascimento</li>
      </ul>
      {/* ... (continuação das informações) */}

      <h3>
        <span>
          4.1.2. Dados informados no formulário de contato
          {/* ... (restante das informações) */}
        </span>
      </h3>
      <p>
        Podemos tratar os dados de seu cadastro nos Serviços ("Dados de
        Cadastro"). Os cadastros podem incluir nome, endereço de e-mail,
        endereço, CPF, telefone, imagem, foto e áudio. Os Dados de Cadastro
        podem ser tratados para fins de operação dos Serviços, fornecimento de
        produtos e serviços, garantia da segurança de nossos Serviços, produtos
        e serviços, manutenção de backup de bases de dados e comunicação com
        nossos clientes, incluindo a disponibilização e acesso à todas as
        funcionalidades dos Serviços. A base legal para o tratamento de tais
        dados é o consentimento. Podemos também tratar seus dados mediante
        acesso à câmera, galeria de vídeos e fotos para upload, bem como a lista
        de contatos do seu dispositivo para completar o cadastro nos Serviços e
        também para que você possa utilizar funções extras dos Serviços e
        Produtos, tais como criar convites e reservar áreas comuns, compartilhar
        mensagens de áudio, vídeo e imagens entre usuários, realizar o cadastro
        em equipamentos com reconhecimento facial."
      </p>
      <h3>
        <span>
          4.1.3. Dados informados no formulário de contato
          {/* ... (restante das informações) */}
        </span>
      </h3>
      <p>
        Os dados eventualmente informados pelo usuário que utilizar o formulário
        de contato disponibilizado no site, incluindo o teor da mensagem
        enviada, serão coletados e armazenados.
      </p>
      <h3>
        <span>
          4.1.4. Dados relacionados à execução de contratos firmados com o
          usuário
          {/* ... (restante das informações) */}
        </span>
      </h3>
      <p>
        Para a execução de contrato de prestação de serviços eventualmente
        firmado entre o site e o usuário, poderão ser coletados e armazenados
        outros dados relacionados ou necessários a sua execução, incluindo o
        teor de eventuais comunicações tidas com o usuário.
      </p>
      <h3>
        <span>
          4.1.5. Newsletter
          {/* ... (restante das informações) */}
        </span>
      </h3>
      <p>
        O endereço de e-mail cadastrado pelo usuário que optar por se inscrever
        em nossa Newsletter será coletado e armazenado até que o usuário
        solicite seu descadastro.
      </p>
      <h3>
        <span>
          4.1.6. Dados sensíveis
          {/* ... (restante das informações) */}
        </span>
      </h3>
      <p>
        Não serão coletados dados sensíveis dos usuários, assim entendidos
        aqueles definidos nos arts, 11 e seguintes da Lei de Proteção de Dados
        Pessoais. Assim, dentre outros, não haverá coleta dos seguintes dados:
      </p>

      <ul>
        <li>
          - dados que revelem a origem racial ou étnica, as opiniões políticas,
          as convicções religiosas ou filosóficas, ou a filiação sindical do
          usuário;
        </li>
        <li>- dados genéticos;</li>
        <li>
          - dados biométricos para identificar uma pessoa de forma inequívoca;
        </li>
        <li>- dados relativos à saúde do usuário;</li>
        <li>
          - dados relativos à vida sexual ou à orientação sexual do usuário;
        </li>
        <li>
          - dados relacionados a condenações penais ou a infrações ou com
          medidas de segurança conexas.
        </li>
      </ul>
      <h3>
        <span>
          4.1.7. Coleta de dados não previstos expressamente
          {/* ... (restante das informações) */}
        </span>
      </h3>
      <p>
        Eventualmente, outros tipos de dados não previstos expressamente nesta
        Política de Privacidade poderão ser coletados, desde que sejam
        fornecidos com o consentimento do usuário, ou, ainda, que a coleta seja
        permitida ou imposta por lei.
      </p>

      <h3>
        <span>
          4.2. Fundamento jurídico para o tratamento dos dados pessoais
          {/* ... (restante das informações) */}
        </span>
      </h3>
      <p>
        Ao utilizar os serviços do site, o usuário está consentindo com a
        presente Política de Privacidade.
      </p>
      <p>
        O usuário tem o direito de retirar seu consentimento a qualquer momento,
        não comprometendo a licitude do tratamento de seus dados pessoais antes
        da retirada. A retirada do consentimento poderá ser feita pelo e-mail:
        atendimento@nbn.com.br.
      </p>
      <p>
        O consentimento dos relativamente ou absolutamente incapazes,
        especialmente de crianças menores de 16 (dezesseis) anos, apenas poderá
        ser feito, respectivamente, se devidamente assistidos ou representados.
      </p>
      <p>
        Poderão ainda ser coletados dados pessoais necessários para a execução e
        cumprimento dos serviços contratados pelo usuário no site.
      </p>
      <p>
        O tratamento de dados pessoais sem o consentimento do usuário apenas
        será realizado em razão de interesse legítimo ou para as hipóteses
        previstas em lei, ou seja, dentre outras, as seguintes:
      </p>
      <ul>
        <li>
          para o cumprimento de obrigação legal ou regulatória pelo controlador;
        </li>
        <li>
          para a realização de estudos por órgão de pesquisa, garantida, sempre
          que possível, o anonimato dos dados pessoais;
        </li>
        <li>
          quando necessário para a execução de contrato ou de procedimentos
          preliminares relacionados a contrato do qual seja parte o usuário, a
          pedido do titular dos dados;
        </li>
        <li>
          para o exercício regular de direitos em processo judicial,
          administrativo ou arbitral, esse último nos termos da Lei nº 9.307, de
          23 de setembro de 1996 (Lei de Arbitragem);
        </li>
        <li>
          para a proteção da vida ou da incolumidade física do titular dos dados
          ou de terceiro;
        </li>
        <li>
          para a tutela da saúde, em procedimento realizado por profissionais da
          área da saúde ou por entidades sanitárias;
        </li>
        <li>
          quando necessário para atender aos interesses legítimos do controlador
          ou de terceiro, exceto no caso de prevalecerem direitos e liberdades
          fundamentais do titular dos dados que exijam a proteção dos dados
          pessoais;
        </li>
        <li>
          para a proteção do crédito, inclusive quanto ao disposto na legislação
          pertinente.
        </li>
      </ul>
      <h3>
        <span>
          4.3. Finalidades do tratamento dos dados pessoais
          {/* ... (restante das informações) */}
        </span>
      </h3>
      <p>
        Os dados pessoais do usuário coletados pelo site têm por finalidade
        facilitar, agilizar e cumprir os compromissos estabelecidos com o
        usuário e a fazer cumprir as solicitações realizadas por meio
        do preenchimento de formulários.
      </p>
      <p>
        Os dados pessoais poderão ser utilizados também com uma finalidade
        comercial, para personalizar o conteúdo oferecido ao usuário, bem como
        para dar subsídio ao site para a melhora da qualidade e funcionamento de
        seus serviços.
      </p>
      <p>
        Os dados de cadastro serão utilizados para permitir o acesso do usuário
        a determinados conteúdos do site, exclusivos para usuários cadastrados.
      </p>
      <p>
        A coleta de dados relacionados ou necessários à execução de um contrato
        de compra e venda ou de prestação de serviços eventualmente firmado com
        o usuário terá a finalidade de conferir às partes segurança jurídica,
        além de facilitar e viabilizar a conclusão do negócio.
      </p>
      <p>
        O tratamento de dados pessoais para finalidades não previstas nesta
        Política de Privacidade somente ocorrerá mediante comunicação prévia ao
        usuário, sendo que, em qualquer caso, os direitos e obrigações aqui
        previstos permanecerão aplicáveis.
      </p>
      <h3>
        <span>
          4.4. Prazo de conservação dos dados pessoais
          {/* ... (restante das informações) */}
        </span>
      </h3>
      <p>
        Os dados pessoais do usuário serão conservados por um período não
        superior ao exigido para cumprir os objetivos em razão dos quais eles
        são processados.
      </p>
      <p>
        O período de conservação dos dados é definido de acordo com os seguintes
        critérios:
      </p>
      <p>
        Os dados serão armazenados enquanto houver o registro ativo do cliente
        na plataforma.
      </p>
      <p>
        Os dados pessoais dos usuários apenas poderão ser conservados após o
        término de seu tratamento nas seguintes hipóteses:
      </p>
      <ul>
        <li>
          - para o cumprimento de obrigação legal ou regulatória pelo
          controlador;
        </li>
        <li>
          - para estudo por órgão de pesquisa, garantida, sempre que possível, a
          anonimização dos dados pessoais;
        </li>
        <li>
          - para a transferência a terceiro, desde que respeitados os requisitos
          de tratamento de dados dispostos na legislação;
        </li>
        <li>
          - para uso exclusivo do controlador, vedado seu acesso por terceiro, e
          desde que anonimizados os dados.
        </li>
      </ul>
      <h3>
        <span>
          4.5. Destinatários e transferência dos dados pessoais
          {/* ... (restante das informações) */}
        </span>
      </h3>
      <p>
        Os dados pessoais do usuário não serão compartilhados com terceiros.
        Serão, portanto, tratados apenas por este site.
      </p>
      <h3>
        <span>
          4.5. Destinatários e transferência dos dados pessoais
          {/* ... (restante das informações) */}
        </span>
      </h3>

      <h3>
        <span>5. Do tratamento dos dados pessoais</span>
      </h3>

      <h3>
        <span>5.1. Do responsável pelo tratamento dos dados</span>
        {/* ... (restante das informações) */}
      </h3>
      <p>
        O controlador, responsável pelo tratamento dos dados pessoais do
        usuário, é a pessoa física ou jurídica, a autoridade pública, a agência
        ou outro organismo que, individualmente ou em conjunto com outras,
        determina as finalidades e os meios de tratamento de dados pessoais.
      </p>
      <p>
        Neste site, o responsável pelo tratamento dos dados pessoais coletados é
        Norte Brasil Network Telecomunicações Ltda-ME, representada por Ademar
        Felippe Mallmann Junior, que poderá ser contatado pelo e-mail:
        @nbntelecom.com.br.
      </p>
      <p>
        O responsável pelo tratamento dos dados se encarregará diretamente do
        tratamento dos dados pessoais do usuário.
      </p>

      <h3>
        <span>
          5.2. Do encarregado de proteção de dados (Data Protection Officer)
        </span>
      </h3>
      <p>
        O encarregado de proteção de dados (Data Protection Officer) é o
        profissional encarregado de informar, aconselhar e controlar o
        responsável pelo tratamento dos dados, bem como os trabalhadores
        que tratem os dados, a respeito das obrigações do site nos termos da Lei
        de Proteção de Dados Pessoais e de outras disposições de proteção de
        dados presentes na legislação nacional e internacional, em cooperação
        com a autoridade de controle competente
      </p>
      <p>
        Neste site o encarregado de proteção de dados (Data Protection Officer)
        é Ademar Felippe Mallmann Junior, que poderá ser contatado pelo e-mail:
        jurídico@nbntelecom.com.br.
      </p>
      <h3>
        <span>6. Segurança no tratamento dos dados pessoais do usuário</span>
      </h3>
      <p>
        O site se compromete a aplicar as medidas técnicas e organizativas aptas
        a proteger os dados pessoais de acessos não autorizados e de situações
        de destruição, perda, alteração, comunicação ou difusão de tais dados.
      </p>
      <p>
        Para a garantia da segurança, serão adotadas soluções que levem em
        consideração: as técnicas adequadas; os custos de aplicação; a natureza,
        o âmbito, o contexto e as finalidades do tratamento; e os riscos para os
        direitos e liberdades do usuário.
      </p>
      <p>
        O site utiliza certificado SSL (Secure Socket Layer) que garante que os
        dados pessoais se transmitam de forma segura e confidencial, de maneira
        que a transmissão dos dados entre o servidor e o usuário, e em
        retroalimentação, ocorra de maneira totalmente cifrada ou encriptada.
      </p>
      <p>
        No entanto, o site se exime de responsabilidade por culpa exclusiva de
        terceiro, como em caso de ataque de hackers ou crackers, ou culpa
        exclusiva do usuário, como no caso em que ele mesmo transfere seus dados
        a terceiro. O site se compromete, ainda, a comunicar o usuário em
        prazo adequado caso ocorra algum tipo de violação da segurança de seus
        dados pessoais que possa lhe causar um alto risco para seus direitos e
        liberdades pessoais.
      </p>
      <p>
        A violação de dados pessoais é uma violação de segurança que provoque,
        de modo acidental ou ilícito, a destruição, a perda, a alteração, a
        divulgação ou o acesso não autorizados a dados pessoais transmitidos,
        conservados ou sujeitos a qualquer outro tipo de tratamento.
      </p>
      <p>
        Por fim, o site se compromete a tratar os dados pessoais do usuário com
        confidencialidade, dentro dos limites legais.
      </p>
      {/* ... (continuação das informações) */}
      <h3>
        <span>7. Dados de navegação (cookies)</span>
      </h3>
      <p>
        Cookies são pequenos arquivos de texto enviados pelo site ao computador
        do usuário e que nele ficam armazenados, com informações relacionadas à
        navegação do site.
      </p>
      <p>
        Por meio dos cookies, pequenas quantidades de informação são armazenadas
        pelo navegador do usuário para que nosso servidor possa lê-las
        posteriormente. Podem ser armazenados, por exemplo, dados sobre o
        dispositivo utilizado pelo usuário, bem como seu local e horário de
        acesso ao site.
      </p>
      <p>
        Os cookies não permitem que qualquer arquivo ou informação sejam
        extraídos do disco rígido do usuário, não sendo possível, ainda, que,
        por meio deles, se tenha acesso a informações pessoais que não tenham
        partido do usuário ou da forma como utiliza os recursos do site.
      </p>
      <p>
        É importante ressaltar que nem todo cookie contém informações que
        permitem a identificação do usuário, sendo que determinados tipos de
        cookies podem ser empregados simplesmente para que o site seja carregado
        corretamente ou para que suas funcionalidades funcionem do modo
        esperado.
      </p>
      <p>
        As informações eventualmente armazenadas em cookies que permitam
        identificar um usuário são consideradas dados pessoais. Dessa forma,
        todas as regras previstas nesta Política de Privacidade também lhes são
        aplicáveis.
      </p>
      <h3>
        <span>7.1. Cookies do site</span>
      </h3>
      <p>
        As informações coletadas por meio destes cookies são utilizadas para
        melhorar e personalizar a experiência do usuário, sendo que alguns
        cookies podem, por exemplo, ser utilizados para lembrar as preferências
        e escolhas do usuário, bem como para o oferecimento de conteúdo
        personalizado.
      </p>
      <h3>
        <span>7.2. Gestão dos cookies e configurações do navegador</span>
        {/* ... (restante das informações) */}
      </h3>
      <p>
        O usuário poderá se opor ao registro de cookies pelo site, bastando que
        desative esta opção no seu próprio navegador ou aparelho.
      </p>
      <p>
        A desativação dos cookies, no entanto, pode afetar a disponibilidade de
        algumas ferramentas e funcionalidades do site, comprometendo seu correto
        e esperado funcionamento. Outra consequência possível é remoção das
        preferências do usuário que eventualmente tiverem sido salvas,
        prejudicando sua experiência.
      </p>
      {/* ... (continuação das informações) */}
      <h3>
        <span>8. Reclamação a uma autoridade de controle</span>
      </h3>
      <p>
        Sem prejuízo de qualquer outra via de recurso administrativo ou
        judicial, todos os titulares de dados têm direito a apresentar
        reclamação a uma autoridade de controle. A reclamação poderá ser feita
        à autoridade da sede do site, do país de residência habitual do usuário,
        do seu local de trabalho ou do local onde foi alegadamente praticada a
        infração.
      </p>

      {/* ... (continuação das informações) */}
      <h3>
        <span>8. Reclamação a uma autoridade de controle</span>
      </h3>
      <p>
        Sem prejuízo de qualquer outra via de recurso administrativo ou
        judicial, todos os titulares de dados têm direito a apresentar
        reclamação a uma autoridade de controle. A reclamação poderá ser feita
        à autoridade da sede do site, do país de residência habitual do usuário,
        do seu local de trabalho ou do local onde foi alegadamente praticada a
        infração.
      </p>
      <h3>
        <span>
          9.1. O editor se reserva o direito de modificar, a qualquer momento e
          sem qualquer aviso prévio...
        </span>
        {/* ... (restante das informações) */}
      </h3>
      <p>
        O editor se reserva o direito de modificar, a qualquer momento e sem
        qualquer aviso prévio, o site as presentes normas, especialmente para
        adaptá-las às evoluções do central@nbn.com.br seja pela disponibilização
        de novas funcionalidades, seja pela supressão ou modificação daquelas já
        existentes.
      </p>
      <p>
        Dessa forma, convida-se o usuário a consultar periodicamente esta página
        para verificar as atualizações.
      </p>
      <p>
        Ao utilizar o serviço após eventuais modificações, o usuário demonstra
        sua concordância com as novas normas. Caso discorde de alguma das
        modificações, deverá pedir, imediatamente, o cancelamento de sua conta e
        apresentar a sua ressalva ao serviço de atendimento, se assim o desejar.
      </p>
      {/* ... (continuação das informações) */}
      <h3>
        <span>10. Do Direito aplicável e do foro</span>
      </h3>
      <p>
        O presente Termos de Privacidade será regido e interpretado segundo a
        legislação brasileira, no idioma português, sendo eleito o Foro da
        Comarca de domicílio do Titular para dirimir qualquer litígio ou
        controvérsia envolvendo o presente documento, salvo ressalva específica
        de competência pessoal, territorial ou funcional pela legislação
        aplicável.
      </p>
    </div>
  )
}

export default PrivacidadeSeventh
