import React from 'react'
import Routes from './Components/routes/Routes.js'
import GlobalStyle from './Components/styles/global'

export default function App() {
  return (
    <>
      <Routes />
      <GlobalStyle />
    </>
  )
}
