import Header from "../Components/Header/Header"
import Footer from '../Components/Footer/Footer.js'

function AvisoPrivacidade () {
    return(
        <div className="App">
            <Header />
            <h2>
                Aviso de Privacidade
            </h2>
            <p style={{textAlign: "justify", width: "90%", padding: "10px", marginBottom: "6%"}}>
                <span style={{lineHeight: "1.8"}}>
                    <h3>AVISO DE PRIVACIDA DE INFORMAÇÕES GERAIS SOBRE USO E RECEBIMENTO DE E-MAILS</h3>
    ​                O remetente e o destinatário dessa mensagem são responsáveis pelo seu conteúdo e endereçamento. 
                    Sem a devida autorização, a reprodução, distribuição ou qualquer outra ação em desconformidade com 
                    as políticas de privacidade da NBN Telecom são proibidas e passíveis de sansões.  
                    Se você não for o destinatário dessa mensagem não divulgue, copie ou encaminhe.  
                    Notifique imediatamente o remetente e apague a mensagem. 
                    A sua privacidade é importante para nós. É política do Womp respeitar
                    a sua privacidade em relação a qualquer informação sua que possamos
                    coletar no site<a style={{textDecoration: 'none'}} href="/">Womp</a>, e outros sites que
                    possuímos e operamos.
                </span>
            </p>

            <p style={{fontSize: 22, color: 'white'}}>
                
                <a style={{ color: 'white'}} href="/privacy">
                     Clique aqui e conheça a politica de privacidade
                </a> 
            </p>
            <Footer />
        </div>
    )
}

export default AvisoPrivacidade