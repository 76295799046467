import * as React from 'react'
import whatsapp from '../Images/whatslogo.png'

export default function Contact() {
  return (
    <header
      style={{
        background: 'linear-gradient(to right, #51235C, #1F0A57)',
        paddingTop: 75,
        paddingBottom: 75
      }}
    >
      <div
        style={{
          color: '#fff',
          fontSize: 35,
          fontFamily: 'Barlow',
          fontWeight: '700',
          marginBottom: 10
        }}
      >
        Contato
      </div>
      <div>
        <a
          href="https://wa.me/9240209933?text=Tenho%20interesse%20em%20comprar%20o%20seu%20produto"
          target="_blank"
          style={{
            color: '#1F0A57',
            fontSize: 24,
            fontFamily: 'Barlow',
            fontWeight: '700',
            paddingLeft: 38,

            borderRadius: 22,
            textDecoration: 'none',
            position: 'absolute',
            margin: 11.5
          }}
          rel="noreferrer"
        >
          <img
            style={{
              width: 60,
              position: 'absolute',
              marginLeft: -65,
              marginTop: -20
            }}
            src={whatsapp}
            alt=""
          />
          92
        </a>
        <a
          href="https://wa.me/9240209933?text=Tenho%20interesse%20em%20comprar%20o%20seu%20produto"
          target="_blank"
          className="no-borderRadiusImportant"
          style={{
            color: '#1F0A57',
            fontSize: 35,
            fontFamily: 'Barlow',
            fontWeight: '700',
            paddingLeft: 80,
            paddingRight: 25,
            borderRadius: 22,
            background: '#FDEB1A',
            textDecoration: 'none'
          }}
          rel="noreferrer"
        >
          4020-9933
        </a>
      </div>
      <p
        style={{
          color: '#fff',
          fontSize: 15,
          fontFamily: 'Barlow',
          fontWeight: '700',
          marginTop: 6
        }}
      >
        comercial@womp.com.br
      </p>
    </header>
  )
}
